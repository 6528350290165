<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-17 16:51:27
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-08 16:56:09
-->
<template>
  <div style="position: relative" v-clickOut="hideDrap" :class="showhometree ? 'gogonghome' : 'gogong'">
    <!-- <Input v-model="select" @click.stop @on-focus="focus" clearable placeholder="请选择部门"></Input> -->
    <v-text-field
      v-model="select"
      :label="labelName"
      hide-details="auto"
      @input="change"
      outlined
      @mousedown="focus"
      :dense="true"
      :height="32"
      :clearable="true"
    ></v-text-field>
    <transition name="fade">
      <div
        v-show="IsShowTree"
        style="
          background-color: #fff;
          position: absolute;
          max-width: 400px;
          min-width: 200px;
          z-index: 111;
          border: 1px solid #eee;
          height: 350px;
          overflow-y: auto;
        "
      > 
        <el-tree
          ref="dept"
          highlight-current
          default-expand-all
          :current-node-key='value'
          :expand-on-click-node="false"
          :node-key="nodeKey"
          :props="defaultProps"
          :filter-node-method="filterNode"
          :data="data"
          @current-change="currentChange"
        ></el-tree>
       </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "DeptSys-Select",
  props: {
    name: null,
    // select: null,
    // 选择框默认文字
    showhometree: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    label: {
      type: String,
      default: "areaName"
    },
    nodeKey:{
      type:String,
      default:''
    },
    value: {
      type: [String,Number],
      default: "value"
    },
    children: {
      type: String,
      default: "children"
    },
    // select和tree数据
    data: {
      type: Array,
    },
    labelName: {
      type: String,
    }
  },
  data() {
    return {
      select: "",
      IsShowTree: false,
      selectModel: null,
      // 数据的key value
      defaultProps: {
        children: this.children,
        label: this.label
      }
    };
  },
  directives: {
    clickOut: {
      bind: (el, binding) => {
        // removeEventListener只能移除对象上的某个具名函数
        function handler(e) {
          if (el.contains(e.target)) return false;
          binding.value();
        }
        el.handler = handler;
        document.addEventListener("click", el.handler);
      },
      unbind: function(el) {
        document.removeEventListener("click", el.handler);
      }
    }
  },
  methods: {
    change(value){
      if(!value){
        this.$nextTick(()=>{
        this.select = ''
        this.$emit("input", '');
        this.$emit("change", '');
      })
      }
      this.$nextTick(()=>{
        this.$refs.dept.filter(value);
      })
    },
    /**设置key */
    setKey(){
        this.$refs.dept.setCurrentKey(this.value)
        this.$nextTick(()=>{
          let data = this.$refs.dept.getCurrentNode()
          if(data){
            this.select = data.deptName
          }
        })
      },
    currentChange(val) {
      this.IsShowTree = false;
      if(val){
        this.$nextTick(()=>{
          this.select = val['deptName']
          this.$emit("input", val[this.nodeKey]);
          this.$emit("change", val[this.nodeKey]);
        })
        return
      }
    },
    // 节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data[this.label].indexOf(value) !== -1;
    },
    hideDrap() {
      this.IsShowTree = false;
    },
    focus() {
      this.IsShowTree = true;
    },
  },
  watch: {
    value(val,oldVal){
      if(val && val!==oldVal){
        this.$nextTick(()=>{
          this.setKey()
        })
      }
    },
    data:{
      deep:true,
      handler(val){
        this.$nextTick(()=>{
          this.setKey()
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setKey()
    });
  }
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.gogonghome {
  padding-top: 4px;
}
</style>

