<template>
  <div class="funnel">
    <div v-for="(item, key) in options" :key="key" class="list">
      <div class="border" :style="`border-color:${listNum[key].color}`"></div>
      <div class="img">
        <div class="img-content">
          <img :src="`static/funnelImg/${key + 1}.png`" alt="" />
          <div class="img-text">
            <div class="name">
              {{item.name}}：{{item.val}}
            </div>
            
          </div>
        </div>
        <div class="val">
          {{item.tooltip}}
        </div>
      </div>
      <div class="text">
        <div class="title" :style="`color:${listNum[key].color}`">{{item.name}}</div>
        <p>
            {{item.rateName}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    options:{
      type:Array,
      default:()=>([])
    }
  },
  data() {
    return {
      listNum: [
        {
          color: "#FFCC41",
        },
        {
          color: "#FF8F68",
        },
        {
          color: "#8937e6",
        },
        {
          color: "#50e0b4",
        },
        {
          color: "#5866e3",
        },
        {
          color: "#35C4FA",
        },
        {
          color: "#F14E8B",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.funnel {
  transform: scale(0.9);
  min-width: 1450px;
  
  .list {
    text-align: center;
    display: flex;
    position: relative;
    // margin-bottom: 10px;
    &:last-child {
      .img {
        .img-content {
          height: 128px;
          .img-text{
            padding:0 30px;
          }
        }
      }
      .text{
        .title{
          margin-top: 38px;
        }
        
      }
    }
    .img {
      user-select: none;
      width: 870px;
      text-align: center;
      margin-right: 120px;
      .val{
        height: 30px;
        line-height: 25px;
      }
      .img-content {
        display: inline-block;
        background: #fff;
        position: relative;
        height: 80px;
        box-sizing: border-box;
        // padding-right: 30px;

        img {
          height: 100%;
          width: 100%;
        }
        .img-text {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          color: #FFFFFF;
          padding: 0 58px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        
          .name{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            text-align: center;
            box-sizing: border-box;
            
          }
          .val{
            font-size: 12px;
            font-family: Microsoft YaHei;
          }
        }
      }
    }
    .text {
      flex: 1;
      text-align: left;
      background: #fff;
      p{
        margin: 0;
      }
    }
    .text {
      position: relative;
      padding-left: 23px;
      box-sizing: border-box;
      .title{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        margin-top: 15px;
      }
      p{
        margin-bottom: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 23px;
        color: #999999;
        opacity: 1;
      }
    }
    .border {
      top: 1;
      position: absolute;
      width: 50%;
      height: 1px;
      border-top: 2px dashed #ffcc41;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto ;
    }
  }
}
</style>
