<template>
  <div class="funnelModel">
    <div style="width: 94%; margin: 0 auto">
      <SearchForm
        :searchAllParam="searchAllParam"
        @search="search"
        @reset="reset"
        @changeItem="change"
        :searchParamList="searchParamList"
        code="count"
      />
    </div>
    <span style="color: red" class="xingxing">*</span>
    <div style="width: 94%; margin: 0 auto">
      <div class="modeltype">
        <div
          class="btn"
          :class="{ active: item.value === searchParam.type }"
          @click="searchParam.type = item.value"
          v-for="(item, key) in tabList"
          :key="key"
        >
          {{ item.name }}
        </div>
      </div>
      <Funnel :options="funnelModelData" v-if="funnelModelData.length"></Funnel>
    </div>
  </div>
</template>

<script>
import { queryFunnelModel } from "@/api/count";
import SearchForm from "components/search-form";
import { formatTimesHour } from "@/utils/timeDate";
import Funnel from "./components/funnel.vue";
import {
  getUserList,
  getRecruitSpinner,
  getDeptList,
  getUserListBynewDept,
} from "api/public";
function doHandleZero(zero) {
  var date = zero;
  if (zero.toString().length == 1) {
    date = "0" + zero;
  }
  return date;
}
function getDate() {
  var myDate = new Date();
  var tYear = myDate.getFullYear();
  var tMonth = myDate.getMonth();
  tMonth = doHandleZero(tMonth + 1);

  return tYear + "-" + tMonth + "-01";
}
function initSearchAllParam() {
  return [
    {
      name: "beginTime",
      label: "日期",
      type: "date",
      ref: "follow",
      show: false,
      value: [],
      // value: [formatTimesHour(getDate()), formatTimesHour(new Date())],
    },
    {
      name: "recruitChannelsId",
      label: "渠道名称",
      type: "allSelect",
      option: [],
      value: [],
      text: "name",
      id: "id",
    },
    {
      name: "deptIdList",
      label: "部门",
      type: "multiTree",
      option: [],
      labelName: "",
      value: "",
    },
    {
      name: "userId",
      label: "人员",
      type: "select",
      option: [],
      value: "",
      text: "name",
      id: "id",
    },
  ];
}
export default {
  components: {
    SearchForm,
    Funnel,
  },
  data() {
    // let data = initSearchAllParam()
    return {
      // funnelModelData: [],
      current: [],
      complex: [],
      searchParam: {
        // beginTime:'',
        // endTime:'',
        // recruitChannelsId:[],
        deptIdList: null,
        type: "0",
        // userId:0
      },
      searchAllParam: initSearchAllParam(),
      searchParamList: [],
      tabList: [
        {
          name: "当期漏斗模型",
          value: "0",
        },
        {
          name: "复合漏斗模型",
          value: "1",
        },
      ],
      loading: false,
    };
  },
  mounted() {
    this.searchParamList = this.searchAllParam;
    this.getUserDeptListselect()
    this.init().then(() => {
      // this.search();
    });
  },
  computed: {
    funnelModelData() {
      return this.searchParam.type === "0" ? this.current : this.complex;
    },
    funnelKey() {
      return JSON.stringify(this.searchAllParam);
    },
  },
  methods: {
    change({ name, value }) {
      if (name === "deptIdList") {
        this.searchAllParam[3].value = [];
        this.searchAllParam[3].option = [];
        if (value) {
          // getUserListBynewDept(value).then(({ code, result }) => {
          //   if (code === 0) {
          //     this.searchAllParam[3].value = [];
          //     this.searchAllParam[3].option = result.map(
          //       ({ userName, userId }) => {
          //         return {
          //           id: userId,
          //           name: userName,
          //         };
          //       }
          //     );
          //   }
          // });
        }
      }
    },
    getUserDeptListselect() {
      getUserListBynewDept().then(({ code, result }) => {
          if (code === 0) {
            this.searchAllParam[3].value = [];
            this.searchAllParam[3].option = result.map(
              ({ name, id }) => {
                return {
                  id: id,
                  name: name,
                };
              }
            );
          }
        });
    },
    init() {
      /**渠道名称 列表数据 */
      let promiseArr = this.searchAllParam.map((item, key) => {
        switch (item.name) {
          case "recruitChannelsId":
            return getRecruitSpinner().then(({ code, result }) => {
              if (code === 0) {
                this.$set(this.searchAllParam[key], "option", result);
              }
            });

          case "deptIdList":
            return getDeptList().then(({ code, result }) => {
              if (code === 0) {
                this.$set(this.searchAllParam[key], "option", result);
                console.log(this.searchAllParam[key], "数据");
              }
            });
          default:
            return Promise.resolve();
        }
      });

      return Promise.all([...promiseArr]).then(() => {
        return getUserListBynewDept().then(
          ({ code, result }) => {
            if (code === 0) {
              this.searchAllParam[3].option = result.map(
                ({ name, id }) => {
                  return {
                    id: id,
                    name: name,
                  };
                }
              );
            }
          }
        );
      });
    },
    getFunnelModul() {
      return queryFunnelModel();
    },
    search() {
      this.searchAllParam.map((item, key) => {
        if (item.type === "date") {
          if (item.name === "beginTime") {
            this.searchParam.beginTime =
              item.value && item.value.length ? item.value[0] : "";
            this.searchParam.endTime =
              item.value && item.value.length ? item.value[1] : "";
          }
        } else {
          this.searchParam[item.name] = item.value || null;
        }
      });
      if(this.searchParam.userId.length == 0) {
        this.searchParam.userId = null
      }
      console.log('this.searchParam.userId', this.searchParam.userId);
      if (
        this.searchParam.recruitChannelsId.length ||
        this.searchParam.userId ||
        this.searchParam.deptIdList
      ) {
        this.loading = true;
        return Promise.all([
          queryFunnelModel({
            ...this.searchParam,
            type: 0,
          }).then(({ code, result, msg }) => {
            if (code === 0) {
              this.current = this.getDataText(result, 0);
              return;
            }
            this.$message.error(msg);
          }),
          queryFunnelModel({
            ...this.searchParam,
            type: 1,
          }).then(({ code, result }) => {
            if (code === 0) {
              // this.funnelModelData =  this.getDataText(result)
              this.complex = this.getDataText(result, 1);
              console.log(result, "模型数据");
            }
          }),
        ]).finally(() => {
          this.loading = false;
        });
      } else {
        this.$message.error("渠道、部门和人员至少选择一个条件");
      }
    },
    getDataText(data, type) {
      let {
        customerNum = 0, //商机量
        callNum = 0, //
        connectNum = 0,
        inviteNum = 0,
        visitNum = 0,
        dealNum = 0,
        orderValidNum = 0,
        customerCallRate = "0.00%", //商机呼出率
        callConnectRate = "0.00%",
        connectInviteRate = "0.00%",
        inviteVisitRate = "0.00%",
        visitDealRate = "0.00%",
        customerDealRate = "0.00%",
        orderValidRate = "0.00%",
      } = data;
      if (type === 0) {
        return [
          {
            name: "商机量",
            value: 140,
            val: customerNum,
            rateName:
              "符合筛选条件的商机数量；日期对应的为商机创建时间范围，渠道名称为商机的渠道名称，部门包括所选部门及其子部门",
            tooltip: `商机呼出率（呼出量/商机量）：${customerCallRate}`,
          },
          {
            name: "呼出量",
            value: 120,
            val: callNum,
            rateName:
              "商机量中，在所选日期内呼叫记录数（以商机为维度，一个商机呼出多次，仅统计一个），过滤掉回呼类型",
            tooltip: `呼出接通率（接通量/呼出量）：${callConnectRate}`,
          },
          {
            name: "接通量",
            value: 100,
            val: connectNum,
            rateName:
              "呼出量中，呼叫记录通时大于或等于50秒的呼出记录数（以商机为维度，一个商机呼通多次超过50秒，仅统计一个）",
            tooltip: `接通邀约率（邀约量/接通量）：${connectInviteRate}`,
          },
          {
            name: "邀约量",
            value: 80,
            val: inviteNum,
            rateName:
              "商机量中，在所选日期内产生预约记录的数量（以商机为维度，一个商机多次预约，仅统计一个）",
            tooltip: `邀约到访率（到访量/邀约量）：${inviteVisitRate}`,
          },
          {
            name: "到访量",
            value: 60,
            val: visitNum,
            rateName:
              "邀约量中，预约记录的的到访状态为“预约到访”或“预约到访流失”的预约记录数量（以商机为维度，一个商机有多个符合条件的预约到访记录，仅统计一个）",
            tooltip: `到访成交率（成交量/到访量）：${visitDealRate}`,
          },
          {
            name: "成交量",
            value: 40,
            val: dealNum,
            rateName:
              "商机量中，在所选日期内商机状态为成交状态的商机数量（以商机为维度，一个商机有多个订单也仅统计为一个）",
            tooltip: `有效成交率（订单有效量/成交量）：${orderValidRate}`,
          },
          {
            name: "订单有效量",
            value: 20,
            val: orderValidNum,
            rateName:
              "商机量中，成交商机对应的订单，且订单为收齐款的订单数量（以商机为维度，一个商机有多个收齐款订单的也仅统计为一个，一个商机只要有一个收齐款的订单都统计）",
            tooltip: "",
          },
        ];
      } else {
        return [
          {
            name: "商机量",
            value: 140,
            val: customerNum,
            rateName:
              "符合筛选条件的商机数量；日期对应的为商机创建时间范围，渠道名称为商机的渠道名称，部门包括所选部门及其子部门",
            tooltip: `商机呼出率（呼出量/商机量）：${customerCallRate}`,
          },
          {
            name: "呼出量",
            value: 120,
            val: callNum,
            rateName:
              "所选日期范围内，渠道商机在部门/人员产生的呼叫记录数（以商机为维度，一个商机呼出多次，仅统计一个），过滤掉回呼类型",
            tooltip: `呼出接通率（接通量/呼出量）：${callConnectRate}`,
          },
          {
            name: "接通量",
            value: 100,
            val: connectNum,
            rateName:
              "呼出量中，呼叫记录通时大于或等于50秒的呼出记录数（以商机为维度，一个商机呼通多次超过50秒，仅统计一个）",
            tooltip: `接通邀约率（邀约量/接通量）：${connectInviteRate}`,
          },
          {
            name: "邀约量",
            value: 80,
            val: inviteNum,
            rateName:
              "所选日期范围内，渠道商机在部门/人员产生的邀约记录数（以商机为维度，一个商机多次预约，仅统计一个）",
            tooltip: `邀约到访率（到访量/邀约量）：${inviteVisitRate}`,
          },
          {
            name: "到访量",
            value: 60,
            val: visitNum,
            rateName:
              "邀约量中，预约记录的的到访状态为“预约到访”或“预约到访流失”的预约记录数量（以商机为维度，一个商机有多个符合条件的预约到访记录，仅统计一个）",
            tooltip: `到访成交率（成交量/到访量）：${visitDealRate}`,
          },
          {
            name: "成交量",
            value: 40,
            val: dealNum,
            rateName:
              "所选日期范围内，渠道商机在所选部门（订单成交校区）及其子部门/人员（招生老师或销售老师）产生的报名单数量（订单来源为“财经校区”，以订单为维度，一个商机有多个订单，统计多个）",
            tooltip: `有效成交率（订单有效量/成交量）：${orderValidRate}`,
          },
          {
            name: "订单有效量",
            value: 20,
            val: orderValidNum,
            rateName:
              "所选日期范围内，渠道商机在所选部门（订单成交校区）及其子部门/人员（招生老师或销售老师）产生的报名单中为交齐款的报名单数量",
            tooltip: "",
          },
        ];
      }
    },
    reset() {
      let searchAllParam = initSearchAllParam();
      searchAllParam.map((item, key) => {
        this.searchAllParam[key].value = item.value;
      });

      this.search();
    },
    handleClick() {
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.funnelModel {
  // background: #F5F5FA;
}
.xingxing {
  position: absolute;
  top: 125px;
  left: 24px;
  font-size: 20px;
}
.modeltype {
  display: flex;
  padding-top: 30px;
  .btn {
    cursor: pointer;
    user-select: none;
    width: 120px;
    height: 36px;
    background: #ffffff;
    line-height: 36px;
    text-align: center;
    color: #333333;
    margin-right: 12px;
    border: 1px solid #e1e4eb;

    &.active {
      border: 1px solid #337aff;
      font-size: 14px;
      font-family: Microsoft YaHei;
      color: #337aff;
      background-color: #e5f2ff;
      background-image: url("~assets/image/select-ok-normal.png");
      background-repeat: no-repeat;
      background-position: top right;
    }
  }
}
</style>
